import React from 'react';
import './home.css'; 
import {Link} from 'react-router-dom'

const Home = () => {

 
  return (
    <div className="glass-container">
      <div className="content">
        <div className="left">
        <h3 className='home-text'>No chemicals ! No additives !</h3>
          <h1 className='h1-home'>Experience the goodness of farm fresh wood pressed oils, which makes life healthier!</h1>
          <h2 className='home-text'>100% filtered woodpressed oil ! </h2>
          <h3 className='h1-home'>We supply all over India!</h3>
          <Link to="/products">
          <button>Wanna try?</button>
          </Link>
          
        </div>
        <div className="right">
          <img src="homeoils.png" alt="homeoils" />
        </div>
      </div>
    </div>
    
    
  );
};

export default Home;