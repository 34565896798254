import { Link, NavLink } from 'react-router-dom'; 
import './navbar.css'

const NavBar = () => {
  return (
    <nav className='nav'>
      <div className='logo-container'>
      <img src="logo.png" alt="logo" className='logo-img'/>
      <Link to="/"  className="noUnderline">
      <h4 className='logo-text'>MNR AGROTECH</h4>
      </Link>
      </div>
      <div className='nav-links'>
      <ul>
        <li>
          <NavLink to="/"  exact="true"  className="nav-link">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about"  exact="true"  className="nav-link">About</NavLink>
        </li>
        <li>
          <NavLink to="/products"  exact="true" className="nav-link">Products</NavLink>
        </li>
        
        <li>
          <NavLink to="/contact"  exact="true" className="nav-link">Contact</NavLink>
        </li>
        
      </ul>
      </div>
    </nav>
  );
};

export default NavBar;