import React from 'react';
import './footer.css'

const Footer = () => {


  return (
    <footer className='footer-container'>
      <p>&copy; 2024 MNR AgroTech. All rights reserved.</p>
    </footer>
  );
};

export default Footer;