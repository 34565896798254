import YouTube from 'react-youtube';
import './about.css'


function About(){
    const videoId=["7KEbrOW1joY","uOzamDzUeeU","YCNoA9FNZqc"];
    

    return (
       <div className="about-container">
        <h1 className='about-h1'>Who we are?</h1>
        <h3 className="about-h3">Mission</h3>
        <p className='about-p'>At the heart of everything we do is a simple belief that everyone deserves the 
            pure goodness of nature. Our mission is to bring you 100% chemical-free cold-pressed oils, 
            carefully crafted to nurture your well-being. We're on a journey to make healthy living 
            easy, one drop at a time. Join us in embracing a lifestyle that's as wholesome and 
            natural as the oils we provide.
        </p>
        <h3 className="about-h3">Vision</h3>
        <p className='about-p'>In our vision for the future, we see a world where choosing wellness is simple and 
            delightful. We want to be your trusted partner in this journey, offering a variety of 
            premium cold-pressed oils that not only enhance your health but also connect you with 
            the authentic essence of nature. Our hope is to inspire a community dedicated to living 
            life to the fullest, naturally and joyfully, through the goodness of our oils.</p>
            <h1 className='about-h1'>What we do?</h1>

            <div className='video-container'>
            {videoId.map((videoIds)=>
            (
                <div className="video-item" key={videoIds}>
                <YouTube videoId={videoIds}  />
                </div>
            ))}
        </div> 
       </div>
       
    )
}

export default About;