import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

const ProductCard = ({ product }) => {
  return (

    <div className="product-card">
      <img src={product.imageUrl} alt={product.name} className='product-img'/>
      <h2 className='product-h2'>{product.name}</h2>
      <p className='product-p-des'>{product.description}</p>
      <p className='product-p-amt'>1000ml: Rs {product.price1}</p>
      <p className='product-p-amt'>500ml: Rs {product.price2}</p>
      <Link to="/contact">
        <button className="contact-button">Order Now</button>
      </Link>
    </div>
    
    
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductCard;