import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube,FaAmazon, FaWhatsapp} from 'react-icons/fa';
import './contact.css'


const Contact = () => {
 
  const facebookUrl = 'https://www.facebook.com/profile.php?id=100090319222806&mibextid=LQQJ4d';
  const instagramUrl = 'https://instagram.com/mnragrotech?utm_source=qr';
  const youtubeUrl = ' https://youtube.com/@mnragrotech?si=QiYUyTduFDa19XEx';
  const amazonUrl = ' https://www.amazon.in/s?me=A2RSOQEUY3WZRT&fbclid=PAAaZarUExDn-mvMrlJXqz7NMKhxu1OCN-s8FbbBVLptdP3d-otdZHjBeWui8_aem_AXRHebRHPU1tXzRo9K2t2sXkvPtivXVKWGTIGJh_xrj5bxbQqEH_tEirAz-lrKgInYQ&ref=sf_seller_app_share_new';
  const phoneNumber = '+919597754524'; 
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  return (
    <div className='contact-container'>
      <h2 className='h2-contact'>Reach Out to Us !!</h2>
      <address className='address'>
      <div className='logo-contact'>
      <img src="logo.png" alt="logo" className='logo-img'/>
      <strong className='strong-contact'>MNR Agrotech Oil mill</strong>
      </div>
        <p className='contact-p'>2/3, North, Simco Rd, SMESC COLONY</p>
        <p className='contact-p'> North Extension, Tiruchirappalli</p>
        <p className='contact-p'>Tamil Nadu-620021</p>
        <p className="contact-p">** GSTIN 33BWTPD7382L3ZU **</p>
        <p className='contact-p'>** An FSSAI Assured Company **</p>
        <strong className='strong-contact'>Ring us : +91 93421 17457 / 95977 54524 </strong><br />
        <strong className='strong-contact'>Email us : mnragrotech@gmail.com</strong>
       
      </address>
      <br/>
      <div className="social-icons">
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer"  style={{color: 'green', fontWeight: "bolder"}}>
          <FaWhatsapp size={30} />
      </a>
        <a  href={facebookUrl} target="_blank" rel="noopener noreferrer" style={{color: 'blue'}}>
          <FaFacebook size={30} />
        </a>
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer" style={{color: '#bc2a8d'}}>
          <FaInstagram size={30} />
        </a>
        <a href={youtubeUrl} target="_blank" rel="noopener noreferrer"  style={{color: 'red'}}>
          <FaYoutube size={30} />
        </a>
        <a href={amazonUrl} target="_blank" rel="noopener noreferrer"  style={{color: 'black'}}>
          <FaAmazon size={30} />
        </a>
      </div>
      
     
    </div>
   
    
  

  
    
  );
};

export default Contact;