import React from 'react';
import products from './data';
import ProductCard from './ProductCard';
import './products.css'

const Products = () => {
  
  return (
    <div className='product-head'>
      
    <div className="products-container">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
    <h5 className='foot-name'>Packing and delivery charges extra</h5>
    </div>
    
  );
};

export default Products;
