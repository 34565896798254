const products = [
    {
      id: 1,
      name: 'Coconut Oil',
      description: 'Pure Wood-pressed oil made from farm fresh coconuts, controls cholesterol level',
      price1 : 250,
      price2 : 125,
      imageUrl: 'coconut.jpg',
    },
    {
      id: 2,
      name: 'Seasame Oil',
      description: 'Sesame oil made with high quality seeds, which is high in antioxidant and good for heart health',
      price1: 390,
      price2: 195,
      imageUrl: 'sesame.jpg',
    },
    {
      id: 3,
      name: 'Groundnut Oil',
      description: 'Finely choosen handpicked groundnuts converted to oil, keeps your blood pressure under control',
      price1: 290,
      price2: 145,
      imageUrl: 'pea.jpg',
    }
    
  ];
  
  export default products;
  