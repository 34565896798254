import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home'
import Products from './components/Products'
import Contact from './components/Contact'
import NavBar from './components/Navbar'
import About from './components/About';
import Footer from './components/Footer';

function App() {

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route
          path="/contact"
          element={
            <>
              <Contact />
              <Footer />
            </>
          }
        />
      </Routes>
  
    </Router>
  );
}

export default App;
